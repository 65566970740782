import React, {useEffect, useRef, useState} from 'react'
import EmpresasVinculadas from '../../modulos/empresasVinculadas'
import Notifications from '../../modulos/notifications'
import { useGlobalState } from '../../shared/context/AppContext';
import Axios from 'axios'
import updateToken from '../../../updateToken'
import Banner from '../../shared/area-logada/banner-rotativo'
import Conteudos from '../../shared/area-logada/conteudos-para-voce'
import Destaques from '../../shared/area-logada/destaques-estaduais'
import Favorites from '../favoritos'
import ModuloDas from '../modulo8/ModuloDas'
import DeletedModal from '../empresasVinculadas/DeletedModal'
import { useCpe } from '../../shared/modalCadastroPJ/useCpe'

export default function MenuLateral(props){
    const perfil = props.perfil && props.perfil.description
    const changePerfil = props.changePerfil
    const [state] = useGlobalState()

    const [links, setLinks] = useState([]);
    const [banners, setBanners] = useState([])
    const [bannersLoaded, setBannersLoaded] = useState(false)
    const [wemUrl, setWemUrl] = useState('');
    const [empresas, setEmpresas] = useState();
    const [empresasCPE, setEmpresasCPE] = useState([])

    const {getPjs, getEmpresasVinculadas} = useCpe();

    function getBanners(baseUrl) {
        Axios.get(baseUrl + '/v1/bannerRotativo')
          .then(res => {
            if (res && res.data) {
              setBanners(res.data)
              setBannersLoaded(true)
            }
          })
          .catch()
    }

    function exibeCadastroPJ() {
        props.exibeCadastroPJ && props.exibeCadastroPJ()
    }

    function updateTokenRedirect(url){
  
      updateToken(state.keycloak,function(){
        window.open(url, '_blank');
      });
  
    }


  
    useEffect(() => {
      async function loadLinks(req) {
           setLinks(req);
      }

      let url = state.env.BACKEND_URL
      Axios.get(`${url}/v1/menu/`).then(res =>{
        const response = res.data.itens;
        links.push(response);
      });
      getBanners(state.env.BACKEND_URL)
      setWemUrl(state.env.REACT_APP_WEM_URL)
      getEmpresasVinculadas((resp) => {
        setEmpresas(resp.data)
      });
    }, [])

    useEffect(() => {
      if (empresas) {
        const cnpjs = [];
        empresas.forEach((e) =>
          e.isPrincipal ? cnpjs.unshift(e.cnpj) : cnpjs.push(e.cnpj)
        );
        getPjs(
          cnpjs,
          (data) => {
            const empresasResponse = data.map((res) => res.status === "fulfilled" && res.value.status < 300 ? res.value.data : null)
              .filter(data => data)
              .map(e => ({...e, isPrincipal: empresas.find(e2 => e2.cnpj == e.cnpj).isPrincipal}))
            setEmpresasCPE(empresasResponse)
          },
          (err) => console.error(err)
        );
      }
    }, [empresas]);


    const [deletedModalProps, setDeletedModalProps] = useState({})

    const RenderHeader = () => {
      return(
        <>
            <h1>Oi, <strong>{state.keycloak.tokenParsed['given_name']}</strong></h1>
            <div className="sb-area-logada__menu-lateral__content__perfil">
                  {perfil
                  ? <>
                      <h6>Seu Perfil:</h6>
                      <a
                      href='#modal-incentivo'
                      onClick={changePerfil}
                      onKeyPress={changePerfil}
                      className="sb-components__modal__open">{perfil}</a>
                  </>
                  : <></>
                  }
              </div>
          <EmpresasVinculadas empresasCPE={empresasCPE} exibeCadastroPJ={exibeCadastroPJ} setDeletedModalProps={setDeletedModalProps}/>
          <hr/>
            { links.map(function(item){
                return (  <ul className="ul-menu-lateral">
                  {item ? item.map(function(multilinks,i){
                        return (<>
                          { multilinks.map(function(links){
                              return <li key={links.title}><a href={links.url} target={links.target} data-sb-acao="clique" data-sb-rotulo={`menu-lateral - ${links.title}`}><img src={links.image} alt=""/>{links.title}</a></li>
                            })
                          }
                        {item[i+1] ? <hr/> : ''}
                        </>)
                      }): ''}
                </ul>)
              })
            }
        </>
      )
    }



    return (
      <>
      <DeletedModal {...deletedModalProps} />
      <div className="row">
        <div className="col-md-3">
          <div className='sb-utilities__desktop'>
            <div className="sb-area-logada__menu-lateral__content__back"></div>
              {RenderHeader()}
            </div>
          </div>
          <div className="col-md-9">
            <div className="sb-area-logada__header__user__text">
                <strong>Meu mural</strong>
                <Notifications/>
            </div>
            {bannersLoaded ? <Banner wemUrl={wemUrl} banners={banners}/> : <div className="sb-loading"><span/></div>}
            <div className='sb-utilities__mobile'> {RenderHeader()} </div>
            <ModuloDas keycloak={state.keycloak} empresasCPE={empresas}/>
            <Favorites keycloak={state.keycloak}/>
            <Conteudos keycloak={state.keycloak}/>
            <Destaques keycloak={state.keycloak}/>
        </div>
      </div>
      </>
      )

}


import React, { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../../shared/context/AppContext";
import { useCpe } from "../../shared/modalCadastroPJ/useCpe";
import EmpresaCard from "./EmpresaCard";

import $ from 'jquery'

function EmpresasVinculadas(props) {
  const [state] = useGlobalState();
  const { getPjs, putPrincipal, deletePjVinculo } = useCpe();

  const [pjs, setPjs] = useState([]);
  const [principal, setPrincipal] = useState("");


  function empresaChangeHandler(cnpj) {
    if (props.empresasCPE) {
      const empresas = props.empresasCPE;
      const empresa = empresas.find((elem) => elem.cnpj === cnpj);
      if (empresa && !empresa.isPrincipal) {
        putPrincipal(empresa.cnpj, () => window.location.reload());
      }
    }
  }

  function exibeCadastroPJ() {
    props.exibeCadastroPJ && props.exibeCadastroPJ();
  }

  function desvincularPj(cnpj) {
    const event = new CustomEvent("desvincular-pj", { detail: cnpj });
    document.dispatchEvent(event);
  }

  useEffect(() => {
    if(pjs.length > 0) {
      $.getScript('/js/owl-carousel-load.js')
    }
  }, [pjs])

  useEffect(() => {
    if (props.empresasCPE) {
      const empresas = props.empresasCPE;
      const cnpjs = [];
      empresas.forEach((e) =>
        e.isPrincipal ? cnpjs.unshift(e.cnpj) : cnpjs.push(e.cnpj)
      );
      const principal = empresas.find((item) => item.isPrincipal);
      if (principal) {
        setPrincipal(principal.cnpj);
      }
      setPjs(empresas.filter((e) => e.situacaoCadastral == null || e.situacaoCadastral == "02" || e.situacaoCadastral == "99" || e.situacaoCadastral == "04"));
      const empresasBaixadas = empresas.filter(
        (e) => e.situacaoCadastral != "02" && e.situacaoCadastral != "04" && e.situacaoCadastral != "99" && e.situacaoCadastral != null
      );
      if (empresasBaixadas.length > 0) {
        const callback = () => {
          props.setDeletedModalProps({...empresasBaixadas[0], isOpen: true, handleAdicionarOutroCnpj: exibeCadastroPJ})
        };
        deletePjVinculo(empresasBaixadas[0].cnpj, callback);
      }
    }
  }, [props.empresasCPE]);

  return (
    <>
      {pjs.length === 0 ? (
        <></>
      ) : (
        <>
        <div className="sb-area-logada__menu-lateral__suas-empresas">
          <h6>Sua(s) empresa(s):</h6>
          <div className="sb-components__carousel">
            <div className="owl-carousel owl-theme">
              {pjs.map((empresa) => (
                <EmpresaCard
                  key={empresa.cnpj}
                  {...empresa}
                  principal={principal}
                  empresaChangeHandler={empresaChangeHandler}
                  desvincularPj={desvincularPj}
                />
              ))}
            </div>
          </div>
        </div>
        </>
       )}
      <div className="sb-utilities__text-center sb-pddt-25">
      <a
        onClick={(e) => exibeCadastroPJ()}
        onKeyPress={(e) => exibeCadastroPJ()}
        className="sb-components__button inline small radius-4 sb-components__modal__open"
        style={{marginLeft: 0}}
        data-sb-acao="clique"
        data-sb-rotulo={`menu-lateral - Adicionar CNPJ`}
      >
        Adicionar CNPJ
      </a>
      </div>
    </>
  );
}


export default EmpresasVinculadas
import React from "react";
import PropTypes from "prop-types";

/**
 * @param empresas                 Lista de empresas
 * @param state
 * @param definirComoPrincipal     Booleano para que indica se a empresa será definida como principal
 * @param setDefinirComoPrincipal  Altera o booleano definirComoPrincipal para !definirComoPrincipal
 * @param removerCnpj
 * @returns
 */
function Empresas({
  empresas = [],
  state = {},
  definirComoPrincipal = [],
  setDefinirComoPrincipal = () => {},
  removerCnpj = (cnpj) => {}
}) {
  function getTipoVinculo({ cpfPessoaResponsavel, socios = [] }) {
    const cpf = state.keycloak.tokenParsed && state.keycloak.tokenParsed.cpf
      ? state.keycloak.tokenParsed.cpf.replaceAll(/\D/g, '')
      : "";
    return cpfPessoaResponsavel === cpf ||
      (socios && socios.find((s) => s.cpf === cpf))
      ? "Proprietário ou Sócio"
      : "Representante";
  }

  function formatCnpj(cnpj = "") {
    if (!cnpj || cnpj.length !== 14) return cnpj;
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }

  /**
    01 = Nula;
    02 = Ativa;
    03 = Suspensa;
    04 = Inapta;
    08 = Baixada;
    99 = Sem Retorno.
  */
  function formatSituacaoCadastral(situacaoCadastral = "") {
    return ["02", "04", "99"].find((s) => s == situacaoCadastral)
      ? "Ativo"
      : "Baixada";
  }

  function handleSetDefinirComoPrincipal(cnpj) {
    if (definirComoPrincipal === cnpj) {
      setDefinirComoPrincipal("");
    } else {
      setDefinirComoPrincipal(cnpj);
    }
  }

  return empresas.length ? (
    empresas.map((empresa, index) => (
      <li key={empresa.cnpj}>
        <button
          type="button"
          class="sb-components-accordion-open sb-not-scroll"
          data-sb-acao="clique"
          data-sb-rotulo="empresa"
        >
          
          
          {empresa.nomeFantasia ?? empresa.razaoSocial}
        </button>
        <div class="sb-components-accordion-content">
          <div className="sb-components__table" id="empresasVincular">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>CNPJ:</strong>
                  </td>

                  <td align="right">
                    {formatCnpj(empresa.cnpj)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Tipo de vínculo:</strong>
                  </td>
                  <td align="right">{getTipoVinculo(empresa)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Situação:</strong>
                  </td>
                  <td align="right">
                    {formatSituacaoCadastral(empresa.situacaoCadastral)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span className="modal-cad-pj__trash-button btn-top" onClick={() => removerCnpj(empresa.cnpj)}></span>
          <button
            type="button"
            data-sb-acao="clique"
            data-sb-rotulo="principal"
            class={`modal-cad-pj__favorite-button ${
              definirComoPrincipal === empresa.cnpj ? "active" : ""
            }`}
            onClick={() => handleSetDefinirComoPrincipal(empresa.cnpj)}
          >{definirComoPrincipal === empresa.cnpj ? "Principal" : "Definir como principal"}
          </button>
        </div>
      </li>
    ))
  ) : (
    <></>
  );
}

Empresas.protoTypes = {
  empresas: PropTypes.array,
  state: PropTypes.object,
  definirComoPrincipal: PropTypes.string,
  setDefinirComoPrincipal: PropTypes.func,
};

export default Empresas;
